.description {
    position: absolute;
    z-index: 0.3;
    color: white;
    text-shadow: 3px 2px 2px rgb(73, 72, 0.338);
}

.carousel-bullet {
    display: flex;
}

.carousel-bullet span {
    width: 45px;
    height: 12px;
    background-color: rgb(179, 178, 176);
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 3px 2px 2px rgb(73, 72, 0.338);
}

.transparent-bg {
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: 0.2;
    top: 0;
    left: 0;
    opacity: 0;
    background-image: linear-gradient(to top, rgba(255,0,0,0), rgb(0, 255, 204));
}