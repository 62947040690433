@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;900&display=swap');

:root {
  --primary-dark: #212934;
  --primary-light: #e9eef5;
  --secondary-dark: #21c2bc;
  --secondary-light: #efefef;
}

.icon {
  color: var(#000);
}

@layer base {
  body {
    @apply font-[Poppins]
  }
  li {
    @apply p-4 text-2xl md:text-xl cursor-pointer
  }

  h1 {
    @apply text-3xl md:text-4xl font-bold
  }

  h2 {
    @apply text-3xl font-bold
  }

  h3 {
    @apply text-xl font-bold
  }

  button {
    @apply p-3 border bg-gradient-to-r from-[#212934] to-[#21c2bc] text-white rounded-md
  }

  .icon {
    @apply text-2xl cursor-pointer
  }
}
